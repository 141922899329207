import React, { Fragment, useEffect, useState } from "react";
import { ActionBar, Button, Icon, PageHeading, TabularColumn, TabularDataSet } from "@opidcore/components";
import LoadingProgress from "@opidcore/components/LoadingProgress";
import File from "../../components/File";
import { useData } from "@opidcore/hooks/WTF";

import { CaseListTabularColumns } from "./ListSupport";

export default function SupportImports(props) {
	const [data, dataSet] = useData("task;newGreaseTrapCases", { method: "fetchNewGreaseTrapCases" });

  useEffect(() => {
    refreshDataSet();
  }, []);

  useEffect(() => {
    APP.getState("handleSocket", (socket) => {     
      if(socket.progress == 100){
        refreshDataSet();
      }
    });
  }, []);

  const refreshDataSet = () => {
    APP.central.Util.clearCache(dataSet.uid).then(() => {
      dataSet.fetch();
    });
  }

  const handleClick = (row, e) => {
		if (e.defaultPrevented == false) {
			APP.redirect("/ui/support/case/" + row.id);
		}
	};

  const handleOnAuxClick = (row, e) => {
    if (e.defaultPrevented == false && e.button == 1) {
      e.preventDefault();
      e.stopPropagation();
      window.open("/ui/support/case/" + row.id, '_blank');
    }
  }

  const doOpenInModal = (e, row) => {
    e.preventDefault();
    e.stopPropagation();

    APP.instance.createModal("/ui/support/case/" + row.id, {afterClose: () => refreshDataSet(), modal_name: "Case #" + row.id})
  }

  const office365Check = () => {
    window.open("/api/v1/testbulkimport/office365Auth", "_new", "width=350,height=650");
  };

  const checkMail = () => {
    APP.showLoading({message: "Importing mail...", children: <LoadingProgress />, show: false }, ()=>{
      APP.central.TestBulkImport.readGreaseTrapMessages().then((r)=>{
        APP.socket.send(JSON.stringify(r));
      });
    })
  }

  /*
  // open excel grease trap import modal
  const excelGreaseImport = () => {
    APP.instance.createModal(<ExcelGreaseImport />, { modal_name: "Excel Grease Trap Import", className:"task-note-modal" });
  }
  */

  return (
    <Fragment>
      <PageHeading>Support Import</PageHeading>

      <ActionBar title="Actions">
        <Button title="Will confirm Office365 login is functional" onClick={() => office365Check()}>Office365 Check</Button>
        <Button title="Process and create cases from the grease trap inbox" onClick={() => checkMail()}>Process Grease Trap Mailbox</Button>
        <Button title="Refresh dataset below" onClick={() => refreshDataSet()}>Refresh Data</Button>
      </ActionBar>

      
      {/*

      <hr />

      <ActionBar title="Actions">
        <Button title="Open modal for importing grease trap cases from excel spreadsheet" onClick={() => excelGreaseImport()}>Excel Import</Button>
      </ActionBar>

      */}

      <h3>New Grease Trap Cases</h3>

      <TabularDataSet dataSet={dataSet} onClick={handleClick} onAuxClick={handleOnAuxClick} noData="Loading or found 0 cases. Clear filter to see all.">
        {CaseListTabularColumns}
        <TabularColumn title="Open in Modal" data={(row) => <Icon icon="pencil" onClick={(e) => doOpenInModal(e, row)} />} />
      </TabularDataSet>
    </Fragment>
  );
}

/*
const ExcelGreaseImport = () => {
  const [currentFile, setCurrentFile] = useState(null);

  useEffect(() => {
    if (currentFile) {
      APP.showLoading({message: "Importing grease trap file...", children: <LoadingProgress />, show: false }, ()=>{
        APP.central.GreaseTrapCase.readGreaseTrapExcel(currentFile).then((r)=>{
          APP.socket.send(JSON.stringify(r));
        });
      })
    }
  }, [currentFile]);

  const uploaded = (file) => {
    setCurrentFile(file);
  };

  return (
    <div>
      <File onUpload={uploaded} label={"Drop Grease Trap Excel File Here"} />
    </div>
  );
};
*/
